import Nishi1 from "./H-3.webp";
import Nishi2 from "./9-4.webp";
import Nishi3 from "./10-4.webp";
import Nishi4 from "./11-3.webp";
import Nishi5 from "./12-1.webp";
import Nishi6 from "./13-1.webp";
import Nishi7 from "./14.webp";
import Nishi8 from "./15.webp";
import Nishi9 from "./16.webp";

const nishi = [
  Nishi1,
  Nishi2,
  Nishi3,
  Nishi4,
  Nishi5,
  Nishi6,
  Nishi7,
  Nishi8,
  Nishi9,
];

export default nishi;
