import Header1 from "./1.webp";
import Header2 from "./2.webp";
import Header3 from "./3.webp";
import Header4 from "./4.webp";
import Header5 from "./5.webp";
import Header6 from "./6.webp";

const header = [Header1, Header2, Header3, Header4, Header5, Header6];

export default header;
