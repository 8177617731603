import Surrounding1 from "./surounding (1).webp";
import Surrounding2 from "./surounding (2).webp";
import Surrounding3 from "./surounding (3).webp";
import Surrounding4 from "./surounding (4).webp";
import Surrounding5 from "./surounding (5).webp";
import Surrounding6 from "./surounding (6).webp";
import Surrounding7 from "./surounding (7).webp";
import Surrounding8 from "./surounding (8).webp";
import Surrounding9 from "./surounding (9).webp";
import Surrounding10 from "./surounding (10).webp";
import Surrounding11 from "./surounding (11).webp";
import Surrounding12 from "./surounding (11).webp";

const surrounding = [
  Surrounding1,
  Surrounding2,
  Surrounding3,
  Surrounding4,
  Surrounding5,
  Surrounding6,
  Surrounding7,
  Surrounding8,
  Surrounding9,
  Surrounding10,
  Surrounding11,
  Surrounding12,
];

export default surrounding;
